import React, { Component } from "react"
import HighchartsReact from "highcharts-react-official"

class Chart extends Component {
  constructor(props) {
    super(props)
    this.highcharts = props.highcharts
    this.type = props.type
    this.chartData = props.chartData
    this.state = {
      chartOptions: props.options,
    }
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    this.abortCont = new AbortController()
    let chartObj = this.chartRef.current.chart
    chartObj.showLoading()
    this.chartData(this.abortCont)
      .then(chartSeries => {
        if (chartSeries) {
          this.setState({
            chartOptions: chartSeries,
          })
          chartObj.hideLoading()
        }
      })
      .catch(err => {
        console.error("Error fetching chart data", err)
      })
  }

  componentWillUnmount() {
    if (this.abortCont) {
      this.abortCont.abort()
    }
  }

  render() {
    return (
      <HighchartsReact
        highcharts={this.highcharts}
        constructorType={this.type}
        options={this.state.chartOptions}
        ref={this.chartRef}
      />
    )
  }
}

export default Chart
