import React, { useState, useEffect } from "react"
import Highcharts from "highcharts/highstock"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Head from "../components/head"
import Chart from "../components/chart"
import tendenzeStyles from "./tendenze.module.scss"
import {
  getHighchartsOptions,
  getDataPeriod,
  getTempChartOptions,
  getTempChartData,
  getDailyTempChartOptions,
  getDailyTempChartData,
  getPressureAndHumidityChartOptions,
  getPressureAndHumidityChartData,
  getRainChartOptions,
  getRainChartData,
  getWindChartOptions,
  getWindChartData,
  getWindDirectionChartOptions,
  getWindDirectionChartData,
} from "../scripts/tendenze"
const ScrollReveal = loadable(() => import("../components/scrollReveal"))

if (typeof window !== `undefined`) {
  Highcharts.setOptions(getHighchartsOptions())
}

const dateFormat = "%H:%M di %A %e %B"

const TendenzePage = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    const abortCont = new AbortController()
    getDataPeriod(abortCont)
      .then(({ start, end }) => {
        setStartDate(start)
        setEndDate(end)
      })
      .catch(_ => {})

    return () => abortCont.abort()
  }, [])

  return (
    <Layout>
      <Head title="Tendenze" />
      <div className={`container pt-3 text-center ${tendenzeStyles.container}`}>
        <ScrollReveal options={{ duration: 1000, origin: "top" }}>
          <h1>Andamento nelle 24 ore</h1>
          {startDate && endDate && (
            <p>
              Dati acquisiti dalle{" "}
              {Highcharts.dateFormat(dateFormat, startDate)}, alle{" "}
              {Highcharts.dateFormat(dateFormat, endDate)}
            </p>
          )}
          {(!startDate || !endDate) && <p>Caricamento dati...</p>}
        </ScrollReveal>
        <div className="row row-cols-1 row-cols-lg-2 g-4">
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 1000, origin: "left" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="stockChart"
                    options={getTempChartOptions()}
                    chartData={getTempChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 1000, origin: "right" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="stockChart"
                    options={getDailyTempChartOptions()}
                    chartData={getDailyTempChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 2000, origin: "left" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="stockChart"
                    options={getPressureAndHumidityChartOptions()}
                    chartData={getPressureAndHumidityChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 2000, origin: "right" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="stockChart"
                    options={getRainChartOptions()}
                    chartData={getRainChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 2000, origin: "left" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="stockChart"
                    options={getWindChartOptions()}
                    chartData={getWindChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
          <div className="col pb-3">
            <ScrollReveal options={{ duration: 2000, origin: "right" }}>
              <div className="scroll-target card mt-2 shadow mb-2">
                <div className="card-body">
                  <Chart
                    highcharts={Highcharts}
                    type="chart"
                    options={getWindDirectionChartOptions()}
                    chartData={getWindDirectionChartData}
                  />
                </div>
              </div>
            </ScrollReveal>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TendenzePage
