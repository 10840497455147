import Highcharts from "highcharts/highstock"
import HcMore from "highcharts/highcharts-more"
if (typeof window !== `undefined`) {
  HcMore(Highcharts)
}

const tempGraphFile = "/Cumulus/tempdata.json"
const dailyTempGraphFile = "/Cumulus/dailytemp.json"
const dailyRainGraphFile = "/Cumulus/dailyrain.json"
const pressureGraphFile = "/Cumulus/pressdata.json"
const humidityGraphFile = "/Cumulus/humdata.json"
const rainGraphFile = "/Cumulus/raindata.json"
const windDataGraphFile = "/Cumulus/winddata.json"
const windDirGraphFile = "/Cumulus/wdirdata.json"

const chartConfig = {
  temp: { units: "C", decimals: 1 },
  wind: { units: "km/h", decimals: 1 },
  rain: { units: "mm", decimals: 1 },
  press: { units: "hPa", decimals: 1 },
  hum: { decimals: 0 },
  uv: { decimals: 1 },
}

const categories = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"]
// Speed ranges: <0.5m/s, 0.5-2, 2-4, 4-6, 6-8, 8-10, >10
const speedRanges = [0.5, 2, 4, 6, 8, 10]

const tooltipFormatter = tooltip => {
  let dateFormat = tooltip.points[0].series.tooltipOptions.xDateFormat
  //let index = tooltip.points[0].point.index
  let ret = `<small>${Highcharts.dateFormat(dateFormat, tooltip.x)}</small><br>`
  tooltip.points.forEach(point => {
    let series = point.series
    let decimals =
      series.options.tooltip.valueDecimals !== undefined
        ? series.options.tooltip.valueDecimals
        : series.tooltipOptions.valueDecimals
    let suffix =
      series.options.tooltip.valueSuffix !== undefined
        ? series.options.tooltip.valueSuffix
        : series.tooltipOptions.valueSuffix
    ret += `<span style="color:${series.color}">\u25CF</span> ${
      series.name
    }: <strong>${Highcharts.numberFormat(
      Highcharts.pick(point.point.value, point.y),
      decimals
    )}${suffix}</strong><br>`
  })
  return ret
}

const formatData = data => {
  return data.map(item => {
    return { x: item[0], y: item[1] }
  })
}

const smoothLine = data => {
  data = formatData(data)
  let i = data.length,
    sum,
    value

  while (i--) {
    data[i].value = value = data[i].y // preserve value for tooltip
    // Set the smoothed value to the average of the closest points, but don't allow
    // it to differ more than 0.5 degrees from the given value
    sum = (data[i - 1] || data[i]).y + value + (data[i + 1] || data[i]).y
    data[i].y = Math.max(value - 0.5, Math.min(sum / 3, value + 0.5))
  }
  return data
}

const sortData = data => {
  return data.sort((a, b) => {
    if (a[0] <= b[0]) {
      return -1
    } else {
      return 1
    }
  })
}

export const getHighchartsOptions = () => {
  let options = {
    lang: {
      loading: "Sto caricando...",
      months: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      weekdays: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ],
      shortMonths: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lugl",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      exportButtonTitle: "Esporta",
      printButtonTitle: "Importa",
      rangeSelectorFrom: "Da",
      rangeSelectorTo: "A",
      rangeSelectorZoom: "Periodo",
      downloadPNG: "Download immagine PNG",
      downloadJPEG: "Download immagine JPEG",
      downloadPDF: "Download documento PDF",
      downloadSVG: "Download immagine SVG",
      printChart: "Stampa grafico",
      thousandsSep: ".",
      decimalPoint: ",",
    },
  }
  return options
}

export const getTempChartOptions = () => {
  let freezing = chartConfig.temp.units === "C" ? 0 : 32
  let options = {
    chart: {
      type: "spline",
      alignTicks: false,
    },
    title: { text: "Temperatura" },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%e %b",
        week: "%e %b %y",
        month: "%b %y",
        year: "%Y",
      },
    },
    yAxis: [
      {
        // left
        title: { text: "Temperatura (°" + chartConfig.temp.units + ")" },
        opposite: false,
        labels: {
          align: "right",
          x: -5,
          formatter: function () {
            return (
              '<span style="fill: ' +
              (this.value <= freezing ? "blue" : "red") +
              ';">' +
              this.value +
              "</span>"
            )
          },
        },
        plotLines: [
          {
            // freezing line
            value: freezing,
            color: "rgb(0, 0, 180)",
            width: 1,
            zIndex: 2,
          },
        ],
      },
      {
        // right
        linkedTo: 0,
        gridLineWidth: 0,
        opposite: true,
        title: { text: null },
        labels: {
          align: "left",
          x: 5,
          formatter: function () {
            return (
              '<span style="fill: ' +
              (this.value <= 0 ? "blue" : "red") +
              ';">' +
              this.value +
              "</span>"
            )
          },
        },
      },
    ],
    legend: { enabled: true },
    plotOptions: {
      series: {
        turboThreshold: 0,
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
              opacity: 0.25,
            },
          },
        },
        cursor: "pointer",
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 0.1,
            },
          },
        },
      },
      line: { lineWidth: 2 },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      split: false,
      valueSuffix: "°" + chartConfig.temp.units,
      valueDecimals: chartConfig.temp.decimals,
      xDateFormat: "%A, %e %b, %H:%M",
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this)
      },
    },
    series: [
      {
        name: "Temperatura",
        zIndex: 99,
      },
      {
        name: "Punto di rugiada",
      },
      {
        name: "Apparente",
      },
      {
        name: "Percepita",
      },
      {
        name: "Indice di calore",
      },
    ],
    rangeSelector: {
      buttons: [
        {
          count: 6,
          type: "hour",
          text: "6h",
        },
        {
          count: 12,
          type: "hour",
          text: "12h",
        },
        {
          type: "all",
          text: "All",
        },
      ],
      inputEnabled: false,
    },
  }
  return options
}

export const getDailyTempChartOptions = () => {
  let freezing = chartConfig.temp.units === "C" ? 0 : 32
  let options = {
    chart: {
      type: "spline",
      alignTicks: false,
    },
    title: { text: "Temperature e precipitazioni giornaliere" },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%e %b",
        week: "%e %b %y",
        month: "%b %y",
        year: "%Y",
      },
    },
    yAxis: [
      {
        // left
        title: {
          text: "Temperatura giornaliera (°" + chartConfig.temp.units + ")",
        },
        opposite: false,
        labels: {
          align: "right",
          x: -5,
          formatter: function () {
            return (
              '<span style="fill: ' +
              (this.value <= 0 ? "blue" : "red") +
              ';">' +
              this.value +
              "</span>"
            )
          },
        },
        plotLines: [
          {
            // freezing line
            value: freezing,
            color: "rgb(0, 0, 180)",
            width: 1,
            zIndex: 2,
          },
        ],
      },
      {
        // right
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: "Precipitazioni giornaliere (" + chartConfig.rain.units + ")",
        },
        labels: {
          align: "left",
          x: 5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
    ],
    legend: { enabled: true },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
              opacity: 0.25,
            },
          },
        },
        cursor: "pointer",
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 0.1,
            },
          },
        },
      },
      line: { lineWidth: 2 },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      split: false,
      xDateFormat: "%A, %e %b",
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this)
      },
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        name: "Temperatura media",
        color: "green",
        zIndex: 99,
        tooltip: {
          valueSuffix: "°" + chartConfig.temp.units,
          valueDecimals: chartConfig.temp.decimals,
        },
      },
      {
        name: "Temperatura minima",
        color: "blue",
        zIndex: 98,
        tooltip: {
          valueSuffix: "°" + chartConfig.temp.units,
          valueDecimals: chartConfig.temp.decimals,
        },
      },
      {
        name: "Temperatura massima",
        color: "red",
        zIndex: 97,
        tooltip: {
          valueSuffix: "°" + chartConfig.temp.units,
          valueDecimals: chartConfig.temp.decimals,
        },
      },
      {
        name: "Precipitazioni",
        color: "rgba(26, 173, 206, 0.7)",
        type: "column",
        yAxis: 1,
        tooltip: {
          valueSuffix: chartConfig.rain.units,
          valueDecimals: chartConfig.rain.decimals,
        },
      },
    ],
  }
  return options
}

export const getPressureAndHumidityChartOptions = () => {
  let options = {
    chart: {
      type: "spline",
      alignTicks: false,
    },
    title: { text: "Pressione e Umidità relativa" },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%e %b",
        week: "%e %b %y",
        month: "%b %y",
        year: "%Y",
      },
    },
    yAxis: [
      {
        // left
        title: { text: "Pressione (" + chartConfig.press.units + ")" },
        opposite: false,
        labels: {
          align: "right",
          x: -5,
          formatter: function () {
            return (
              '<span style="fill: ' +
              (this.value <= 1013.25 ? "blue" : "red") +
              ';">' +
              this.value +
              "</span>"
            )
          },
        },
      },
      {
        // right
        gridLineWidth: 0,
        opposite: true,
        title: { text: "Umidità relativa (%)" },
        labels: {
          align: "left",
          x: 5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
    ],
    legend: { enabled: true },
    plotOptions: {
      series: {
        turboThreshold: 0,
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
              opacity: 0.25,
            },
          },
        },
        cursor: "pointer",
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 0.1,
            },
          },
        },
      },
      line: { lineWidth: 2 },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      split: false,
      xDateFormat: "%A, %e %b, %H:%M",
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this)
      },
    },
    series: [
      {
        name: "Pressione",
        color: "rgba(26, 173, 206, 1)",
        tooltip: {
          valueSuffix: chartConfig.press.units,
          valueDecimals: chartConfig.press.decimals,
        },
      },
      {
        name: "Umidità",
        color: "green",
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
          valueDecimals: chartConfig.hum.decimals,
        },
      },
    ],
    rangeSelector: {
      buttons: [
        {
          count: 6,
          type: "hour",
          text: "6h",
        },
        {
          count: 12,
          type: "hour",
          text: "12h",
        },
        {
          type: "all",
          text: "All",
        },
      ],
      inputEnabled: false,
    },
  }
  return options
}

export const getRainChartOptions = () => {
  let options = {
    chart: {
      type: "spline",
      alignTicks: true,
    },
    title: { text: "Precipitazioni" },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%e %b",
        week: "%e %b %y",
        month: "%b %y",
        year: "%Y",
      },
    },
    yAxis: [
      {
        // left
        title: {
          text: "Intensità di pioggia (" + chartConfig.rain.units + "/h)",
        },
        min: 0,
        opposite: false,
        labels: {
          align: "right",
          x: -5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
      {
        // right
        opposite: true,
        title: { text: "Precipitazioni (" + chartConfig.rain.units + ")" },
        min: 0,
        labels: {
          align: "left",
          x: 5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
    ],
    legend: { enabled: true },
    plotOptions: {
      series: {
        turboThreshold: 0,
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
              opacity: 0.25,
            },
          },
        },
        cursor: "pointer",
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 0.1,
            },
          },
        },
      },
      line: { lineWidth: 2 },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      valueDecimals: chartConfig.rain.decimals,
      split: false,
      xDateFormat: "%A, %e %b, %H:%M",
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this)
      },
    },
    series: [
      {
        name: "Intensità di pioggia",
        type: "spline",
        color: "orange",
        zIndex: 99,
        yAxis: 0,
        tooltip: { valueSuffix: chartConfig.rain.units + "/h" },
      },
      {
        name: "Precipitazioni",
        type: "areaspline",
        color: "rgba(26, 173, 206, 1)",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              typeof window !== `undefined`
                ? Highcharts.getOptions().colors[0]
                : "#ffffff",
            ],
            [
              1,
              typeof window !== `undefined`
                ? Highcharts.Color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba")
                : "#ffffff",
            ],
          ],
        },
        yAxis: 1,
        tooltip: { valueSuffix: chartConfig.rain.units },
      },
    ],
    rangeSelector: {
      buttons: [
        {
          count: 6,
          type: "hour",
          text: "6h",
        },
        {
          count: 12,
          type: "hour",
          text: "12h",
        },
        {
          type: "all",
          text: "All",
        },
      ],
      inputEnabled: false,
    },
  }
  return options
}

export const getWindChartOptions = () => {
  let options = {
    chart: {
      type: "spline",
      alignTicks: false,
    },
    title: { text: "Vento" },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%e %b",
        week: "%e %b %y",
        month: "%b %y",
        year: "%Y",
      },
    },
    yAxis: [
      {
        // left
        title: { text: "Velocità del vento (" + chartConfig.wind.units + ")" },
        opposite: false,
        min: 0,
        labels: {
          align: "right",
          x: -5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
      {
        // right
        linkedTo: 0,
        gridLineWidth: 0,
        opposite: true,
        min: 0,
        title: { text: null },
        labels: {
          align: "left",
          x: 5,
          formatter: function () {
            return '<span style="fill: black;">' + this.value + "</span>"
          },
        },
      },
    ],
    legend: { enabled: true },
    plotOptions: {
      series: {
        turboThreshold: 0,
        dataGrouping: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 5,
              opacity: 0.25,
            },
          },
        },
        cursor: "pointer",
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 0.1,
            },
          },
        },
      },
      line: { lineWidth: 2 },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      split: false,
      valueSuffix: chartConfig.wind.units,
      valueDecimals: chartConfig.wind.decimals,
      xDateFormat: "%A, %e %b, %H:%M",
      useHTML: true,
      formatter: function () {
        return tooltipFormatter(this)
      },
    },
    series: [
      {
        name: "Velocità",
        color: "rgba(26, 173, 206, 1)",
      },
      {
        name: "Raffiche",
        color: "green",
      },
    ],
    rangeSelector: {
      buttons: [
        {
          count: 6,
          type: "hour",
          text: "6h",
        },
        {
          count: 12,
          type: "hour",
          text: "12h",
        },
        {
          type: "all",
          text: "All",
        },
      ],
      inputEnabled: false,
    },
  }
  return options
}

export const getWindDirectionChartOptions = () => {
  let options = {
    chart: {
      type: "column",
      polar: true,
    },
    title: { text: "Rosa dei venti" },
    subtitle: { text: "Periodo: " },
    credits: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: {
      tickmarkPlacement: "on",
      categories: categories,
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,
      title: {
        text: "Frequenza (%)",
      },
      labels: {
        formatter: function () {
          return this.value + "%"
        },
      },
      reversedStacks: false,
    },
    legend: { enabled: true },
    plotOptions: {
      series: {
        stacking: "normal",
        shadow: false,
        groupPadding: 0,
        pointPlacement: "on",
      },
    },
    tooltip: {
      valueSuffix: "%",
      valueDecimals: chartConfig.wind.decimals,
    },
    series: [
      {
        name: "< " + speedRanges[0] + " m/s",
      },
      {
        name: speedRanges[0] + "-" + speedRanges[1] + " m/s",
      },
      {
        name: speedRanges[1] + "-" + speedRanges[2] + " m/s",
      },
      {
        name: speedRanges[2] + "-" + speedRanges[3] + " m/s",
      },
      {
        name: speedRanges[3] + "-" + speedRanges[4] + " m/s",
      },
      {
        name: speedRanges[4] + "-" + speedRanges[5] + " m/s",
      },
      {
        name: "> " + speedRanges[5] + " m/s",
      },
    ],
  }
  return options
}

export const getTempChartData = async abortController => {
  try {
    let response = await fetch(tempGraphFile, {
      signal: abortController.signal,
    })
    let chartData = await response.json()
    let series = [
      { data: smoothLine(sortData(chartData.temp)) },
      { data: smoothLine(sortData(chartData.dew)) },
      { data: smoothLine(sortData(chartData.apptemp)) },
      { data: smoothLine(sortData(chartData.wchill)) },
      { data: smoothLine(sortData(chartData.heatindex)) },
    ]
    return { series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Temp data fetch request aborted")
    } else {
      console.error("Error getting temp data: ", e)
    }
    return null
  }
}

export const getDailyTempChartData = async abortController => {
  try {
    let response = await Promise.all([
      fetch(dailyTempGraphFile, { signal: abortController.signal }),
      fetch(dailyRainGraphFile, { signal: abortController.signal }),
    ])
    let chartData = await Promise.all([response[0].json(), response[1].json()])
    let series = [
      { data: sortData(chartData[0].avgtemp) },
      { data: sortData(chartData[0].mintemp) },
      { data: sortData(chartData[0].maxtemp) },
      { data: sortData(chartData[1].dailyrain) },
    ]
    return { series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Daily temp data fetch request aborted")
    } else {
      console.error("Error getting daily temp data: ", e)
    }
    return null
  }
}

export const getPressureAndHumidityChartData = async abortController => {
  try {
    let response = await Promise.all([
      fetch(pressureGraphFile, { signal: abortController.signal }),
      fetch(humidityGraphFile, { signal: abortController.signal }),
    ])
    let chartData = await Promise.all([response[0].json(), response[1].json()])
    let series = [
      { data: smoothLine(sortData(chartData[0].press)) },
      { data: smoothLine(sortData(chartData[1].hum)) },
    ]
    return { series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Pressure and humidity data fetch request aborted")
    } else {
      console.error("Error getting pressure and humidity data: ", e)
    }
    return null
  }
}

export const getRainChartData = async abortController => {
  try {
    let response = await fetch(rainGraphFile, {
      signal: abortController.signal,
    })
    let chartData = await response.json()
    let series = [
      { data: sortData(chartData.rrate) },
      { data: sortData(chartData.rfall) },
    ]
    return { series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Rain data fetch request aborted")
    } else {
      console.error("Error getting rain data: ", e)
    }
    return null
  }
}

export const getWindChartData = async abortController => {
  try {
    let response = await fetch(windDataGraphFile, {
      signal: abortController.signal,
    })
    let chartData = await response.json()
    let series = [
      { data: smoothLine(sortData(chartData.wspeed)) },
      { data: smoothLine(sortData(chartData.wgust)) },
    ]
    return { series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Wind data fetch request aborted")
    } else {
      console.error("Error getting wind data: ", e)
    }
    return null
  }
}

export const getWindDirectionChartData = async abortController => {
  try {
    let response = await Promise.all([
      fetch(windDataGraphFile, { signal: abortController.signal }),
      fetch(windDirGraphFile, { signal: abortController.signal }),
    ])
    let chartData = await Promise.all([response[0].json(), response[1].json()])
    let dateFormat = "%A, %e %b, %H:%M"
    let bearing = sortData(chartData[1].bearing)
    let start = Highcharts.dateFormat(dateFormat, bearing[0][0])
    let end = Highcharts.dateFormat(dateFormat, bearing[bearing.length - 1][0])
    let windDirData = formatWindDirectionData(bearing, chartData[0].wspeed)
    let series = [
      { data: windDirData.s1 },
      { data: windDirData.s2 },
      { data: windDirData.s3 },
      { data: windDirData.s4 },
      { data: windDirData.s5 },
      { data: windDirData.s6 },
      { data: windDirData.s7 },
    ]
    return { subtitle: { text: "Da " + start + " a " + end }, series: series }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Wind direction data fetch request aborted")
    } else {
      console.error("Error getting wind direction data: ", e)
    }
    return null
  }
}

export const getDataPeriod = async abortController => {
  try {
    let response = await fetch(tempGraphFile, {
      signal: abortController.signal,
    })
    let chartData = await response.json()
    chartData.temp = sortData(chartData.temp)
    return {
      start: chartData.temp[0][0],
      end: chartData.temp[chartData.temp.length - 1][0],
    }
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Data period fetch request aborted")
    } else {
      console.error("Error getting data period: ", e)
    }
    return { start: null, end: null }
  }
}

const formatWindDirectionData = (dir, speed) => {
  let newData = []
  for (let i in dir) {
    let timestamp = dir[i][0]
    let wspeed = popFromArray(speed, timestamp)
    if (wspeed !== undefined) {
      newData.push([dir[i][1], wspeed[1]])
    }
  }
  // 1Km/h = 0.278m/s
  newData = sortData(newData)
  let windRoseSeries = {
    s1: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s2: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s3: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s4: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s5: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s6: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
    s7: { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 },
  }
  //let seriesLengths = { s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0 };
  //let directionsLengths = { N: 0, NE: 0, E: 0, SE: 0, S: 0, SW: 0, W: 0, NW: 0 };
  let totalLength = 0
  for (let i in newData) {
    let direction = getWindDirectionName(newData[i][0])
    if (direction !== undefined) {
      let speed = newData[i][1] * 0.278
      let speedSerie = getWindSpeedSeriesName(speed)
      //directionsLengths[direction]++;
      //seriesLengths[speedSerie]++;
      windRoseSeries[speedSerie][direction]++
      totalLength++
    }
  }
  for (let s in windRoseSeries) {
    let serie = []
    for (let d in windRoseSeries[s]) {
      serie.push((windRoseSeries[s][d] / totalLength) * 100)
    }
    windRoseSeries[s] = serie
  }
  return windRoseSeries
}

const popFromArray = (array, timestamp) => {
  for (let i in array) {
    if (array[i][0] === timestamp) {
      let found = array[i]
      array.splice(i, 1)
      return found
    }
  }
  return undefined
}

const getWindSpeedSeriesName = speed => {
  if (speed <= speedRanges[0]) {
    return "s1"
  } else if (speed < speedRanges[1]) {
    return "s2"
  } else if (speed < speedRanges[2]) {
    return "s3"
  } else if (speed < speedRanges[3]) {
    return "s4"
  } else if (speed < speedRanges[4]) {
    return "s5"
  } else if (speed < speedRanges[5]) {
    return "s6"
  } else {
    return "s7"
  }
}

const getWindDirectionName = direction => {
  if (direction <= 22.5) {
    return "N"
  } else if (direction <= 67.5) {
    return "NE"
  } else if (direction <= 112.5) {
    return "E"
  } else if (direction <= 157.5) {
    return "SE"
  } else if (direction <= 202.5) {
    return "S"
  } else if (direction <= 247.5) {
    return "SW"
  } else if (direction <= 292.5) {
    return "W"
  } else if (direction <= 337.5) {
    return "NW"
  } else if (direction <= 360) {
    return "N"
  }
  return undefined
}
